import React, { useEffect, useState } from 'react';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Container from '@mui/joy/Container';
import Button from '@mui/joy/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import css from './SignIn.module.css';
import Error from '../../components/Error';
import { emailPattern, passwordPattern } from '../../utils/patterns';
import { useDispatch, useSelector } from 'react-redux';

import { getGlobalUser } from '../../redux/auth/selectors';
import { signIn } from '../../redux/auth/operations';
import { Input } from '@mui/joy';
import { AppDispatch } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import ToggleLanguage from '../../components/ToggleLanguage/ToggleLanguage';
import useWidth from '../../hooks/useWidth';
import Logo from '../../components/Logo/Logo';

interface IFormInput {
  email: string;
  password: string;
  multipleErrorInput: string;
}

const SignIn = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { email} = useSelector(getGlobalUser);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });
  const { isMobile } = useWidth();
  useEffect(() => {
    if (email) navigate(`/confirm-login`);
  }, [email, navigate]);

  const onSubmit = ({ email, password }: { email: string; password: string }) => {
    dispatch(signIn({ email: email.trim(), password: password.trim() }));
  };
  const toggleShowPassword = () => setShowPassword(!showPassword);
  
  return (
    <>
      <Box
        sx={{
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'fixed',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
        }}
      >
        <Box>
          <Box sx={{ position: 'absolute', top: '20px', left: isMobile ? '10px' : '20px' }}>
            <Logo />
          </Box>
          {isMobile && (
            <Box sx={{ position: 'absolute', top: '20px', right: '10px' }}>
              <ToggleLanguage />
            </Box>
          )}
        </Box>

        <div className={css.wrapper}>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: isMobile ? 3 : 0,
              }}
            >
              <Typography
                component="h1"
                sx={{ fontWeight: '700', marginBottom: '20px', fontSize: isMobile ? '20px' : '32px' }}
              >
                {t('login_header')}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
                <Input
                  {...register('email', {
                    required: t('validation_required'),
                    pattern: {
                      value: emailPattern,
                      message: t('validation_email'),
                    },
                  })}
                  id="email"
                  name="email"
                  placeholder={t('login_email')}
                  className={css.fullInput}
                  autoComplete="email"
                  autoFocus
                  sx={{
                    borderColor: 'transparent',
                    '--Input-placeholderOpacity': 1,
                    '--Input-focusedInset': '#18191A',
                    '--Input-focusedThickness': '0',
                    backgroundColor: '#18191A',
                    '&:focus-within': {
                      backgroundColor: '#18191A',
                    },
                    '--Input-focused': 0,
                    borderBottom: watch('email')
                      ? errors.email || !isValid
                        ? '1px solid #EB5757'
                        : '1px solid #18E806'
                      : '',            
}}
                />
                <Error errors={errors} name="email" />
                <div className={css.passWrap}>
                  <Input
                    {...register('password', {
                        required: t('validation_required'),
                      pattern: {
                        value: passwordPattern,
                        message: t('validation_password'),
                      },
                    })}
                    name="password"
                    placeholder={t('login_password')}
                    className={css.fullInput}
                    type={(showPassword && 'text') || 'password'}
                    id="password"
                    autoComplete="password"
                    sx={{
                      borderColor: 'transparent',
                      '--Input-placeholderOpacity': 1,
                      '--Input-focusedInset': '#18191A',
                      '--Input-focusedThickness': '0',
                      backgroundColor: '#18191A',
                      '&:focus-within': {
                        backgroundColor: '#18191A',
                      },
                      '--Input-focused': 0,
                      borderBottom: watch('password')
                        ? errors.password || !isValid
                          ? '1px solid #EB5757'
                          : '1px solid #18E806'
                        : '',
}}
                  />
                  <Error errors={errors} name="password" />
                  <button className={css.buttonPass} type="button" onClick={toggleShowPassword}>
                    {(showPassword && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none">
                        <path
                          fill="#ADB5BD"
                          d="M9.84 5.58A5.256 5.256 0 0 1 12 5.1a5.4 5.4 0 0 1 5.4 5.4 5.207 5.207 0 0 1-.348 1.86l3.348 3.108A16.799 16.799 0 0 0 24 10.5s-3.6-8.4-12-8.4a11.556 11.556 0 0 0-4.8 1.02l2.64 2.46ZM2.4.9 1.2 2.1l3.06 2.88A16.668 16.668 0 0 0 0 10.5s3.6 8.4 12 8.4c1.943.01 3.858-.47 5.568-1.392L21.6 21.3l1.2-1.2L2.4.9Zm9.6 15a5.4 5.4 0 0 1-5.4-5.4 5.34 5.34 0 0 1 .72-2.64l1.836 1.728c-.1.294-.152.602-.156.912a2.988 2.988 0 0 0 4.092 2.784l1.848 1.74A5.363 5.363 0 0 1 12 15.9Z"
                        />
                      </svg>
                    )) || (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18">
                          <path
                            fill="#ADB5BD"
                            d="M12 5.6c-.868 0-1.7.358-2.314.996A3.468 3.468 0 0 0 8.727 9c0 .902.345 1.767.959 2.404A3.212 3.212 0 0 0 12 12.4c.868 0 1.7-.358 2.314-.996A3.469 3.469 0 0 0 15.273 9c0-.902-.345-1.767-.959-2.404A3.212 3.212 0 0 0 12 5.6Zm0 9.067a5.353 5.353 0 0 1-3.857-1.66A5.78 5.78 0 0 1 6.545 9a5.78 5.78 0 0 1 1.598-4.007A5.353 5.353 0 0 1 12 3.333c1.447 0 2.834.597 3.857 1.66A5.78 5.78 0 0 1 17.455 9a5.78 5.78 0 0 1-1.598 4.007A5.353 5.353 0 0 1 12 14.667ZM12 .5C6.545.5 1.887 4.025 0 9c1.887 4.975 6.545 8.5 12 8.5 5.454 0 10.113-3.525 12-8.5C22.113 4.025 17.454.5 12 .5Z"
                          />
                        </svg>
                      )}
                  </button>
                </div>
                <Button className={css.button} type="submit" disabled={!isValid}
                  sx={{
                    mt: 3,
                    p: 2,
                    borderRadius: '8px',
                    width: '100%',
                    background: !isValid
                      ? '#495057'
                      : 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                    color: '#f1f1f1',
                    '&:hover': {
                      background:
                        'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                    },
                  }}>
                  {t('login_btn')}
                </Button>
              </form>
            </Box>
          </Container>
        </div>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          padding: isMobile ? 0 : '80px 80px 0 0',
        }}
      >
      <Box
        sx={{
          height: '100%',
          backgroundSize: '100% auto',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/Illustration.png)',
        }}
      >
        <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
          <ToggleLanguage />
        </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
