import { Box, Button, Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { Transactions } from '../../../utils/types';
import { useTranslation } from 'react-i18next';
import HistoryItemModal from './HistoryItemModal';
import { maskCreditCardNumber } from '../../../utils/formatCreditCard';
import { formatDateTime } from '../../../utils/dateFormater';
import { getStatusTextColor } from '../../../utils/getStatusColor';
const TransactionById = ({ transactionById }: { transactionById: Transactions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{ width: '10%', color: '#F1F1F1' }}>{t('history_table_name')}</th>
            <th style={{ width: '10%', color: '#F1F1F1' }}>{t('history_table_card')}</th>
            <th style={{ width: '10%', color: '#F1F1F1' }}>{t('history_table_priceUah')}</th>
            <th style={{ width: '10%', color: '#F1F1F1' }}>{t('history_table_priceUsdt')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_startDate')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_endDate')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_status')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          <tr key={transactionById.id}>
            <td>
              <Typography level="body-xs">
                {(transactionById.label && transactionById.label) || t('not_found')}
              </Typography>
            </td>
            <td>
              <Typography level="body-xs">
                {maskCreditCardNumber(transactionById.pocket_address)}
              </Typography>
            </td>
            <td>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Typography level="body-md" sx={{ fontWeight: 700 }}>
                  {Number(transactionById.confirmed_amount_uah).toFixed(2)}
                </Typography>
              </Box>
            </td>
            <td>
              <Typography level="body-md">
                {Number(transactionById.merchantAmount).toFixed(2)}
              </Typography>
            </td>
            <td>
              <Typography level="body-md">{formatDateTime(transactionById.createdAt)}</Typography>
            </td>
            <td>
              <Typography level="body-md">{formatDateTime(transactionById.updatedAt)}</Typography>
            </td>
            <td>
              <Typography
                level="body-md"
                sx={{ color: getStatusTextColor(transactionById?.status) }}
              >
                {transactionById?.status &&
                  transactionById.status.charAt(0).toUpperCase() +
                    transactionById.status.slice(1).toLowerCase()}
              </Typography>
            </td>
            <td>
              <Button
                variant="plain"
                sx={{
                  display: 'block',
                  m: 0,
                  borderBottom: '1px solid #947EFE',
                  color: '#947EFE',
                  borderRadius: 0,
                  p: 0,
                }}
                onClick={toggleDrawer(true)}
              >
                {t('history_table_details')}
              </Button>
            </td>
          </tr>
          <HistoryItemModal
            id={transactionById.id}
            open={open}
            onClose={toggleDrawer(false)}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          />
        </tbody>
      </Table>
    </>
  );
};

export default TransactionById;
