
import { Box, Typography, Sheet, Input, Select, Option, Button, Modal } from '@mui/joy';

import { DateRange, RangeKeyDict } from 'react-date-range';


import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { SyntheticEvent, useState } from 'react';

import ReactPaginate from 'react-paginate';

import { useTranslation } from 'react-i18next';
import { useQACTransaction } from '../../hooks/useQACTransaction';
import { status } from '../../db/allDbs';
import { formatDateText } from '../../utils/datePickerFormat';
import QACHistoryTrans from '../../components/QACHistoryTrans';
import { downloadHistoryQAC } from '../../services/operators';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import css from './History.module.css';
export const initialFilterState = {
  searchQuery: '',
  searchBank: null,
  searchStatus: null,
  sDate: null,
  fDate: null,
  sort: null,
};
import useWidth from '../../hooks/useWidth';
import ListMobileQac from '../../components/QACHistoryTrans/Mobile/ListMobileQac';
import { itemType } from '../../utils/types';
type ItemRange = {
  selection: itemType;
}
const TransactionsQac = () => {
  const { t } = useTranslation();
  const { isMobile, isLargeDesktop } = useWidth();
  const [filterQueries, setFilterQueries] = useState<{
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }>(initialFilterState);
  const { notifications, pageCount, handlePageClick, banks} = useQACTransaction({
    filterQueries,
  });
  const { access_token } = useSelector(getGlobalUser);
  const [open, setOpen] = useState(false);
  const [datePickerState, setDatePickerState] = useState<
    {
      startDate: Date;
      endDate: Date;
      key: string;
    }[]
  >([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleRestSearch = () => {
    setFilterQueries(initialFilterState);
  };

  const toggleOpenModal = () => {
    setOpen(!open);
  };

  const handleChangeSort = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, sort: value };
    });
  };

  const handleChangeStatus = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, searchStatus: value };
    });
  };
  const handleChangeBank = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, searchBank: value };
    });
  };


  const handleChangeDate = (item: ItemRange) => {
    setDatePickerState([item.selection]);
  };

  const handleAddDeadline = async () => {
    setFilterQueries(prev => {
      return {
        ...prev,
        sDate: datePickerState[0].startDate,
        fDate: datePickerState[0].endDate,
      };
    });

    setOpen(!open);
  };
  const handleDownload = async () => {
    try {
      await downloadHistoryQAC(filterQueries, access_token);
    } catch (error) {
      console.error('Error while downloading history', error);
    }
  };

  return (
    <Box sx={{ mb: 3, pl:isLargeDesktop ? 3 : 0 }}>
      <Typography level="h2" sx={{ p: '20px 0 ' }}>
        {t('sidebarTRHistoryQAC')}
      </Typography>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input size="sm" placeholder="Search" sx={{ flexGrow: 1 }} />
      </Sheet>
      <Button className={css.btnGradient} sx={{
        padding: '6px 14px',
        color: '#f1f1f1',
        '&:hover': {
          background:
            'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
        },
      }}
        variant="outlined"
        type="button"
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          handleDownload();
        }}>
        {t('transactionBtn')}
      </Button>
      <Box
        sx={{
          py: 2,
          display: isMobile ? 'block' : 'flex',
          gap: 1.5,
        }}
      >
        <Button
          variant="outlined"
          className={css.btnGradient}
          onClick={handleRestSearch}
          sx={{
            '&:hover': {
              background:
                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              color: '#f1f1f1',
            },
          }}
        >
          {t('resetFilters')}
        </Button>
        <Select
          placeholder={t('search_status')}
          variant="outlined"
          slotProps={{
            listbox: {
              sx: {
                '--List-padding': '0px',
                '--ListItem-radius': '0px',
              },
            },
          }}
          indicator={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#ADB5BD"
                d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
              />
            </svg>
          }
          onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
            handleChangeStatus(_event, newValue);
          }}
          value={filterQueries.searchStatus}
          sx={{
            color: '#CED4DA',
            width: '180px',
            p: 1,
            '--Input-radius': '8px',
            '--Select-placeholderOpacity': 1,
            mt: isMobile ? 1 : 0,
          }}
        >
          <Option key={'all'} value={'all'} sx={{ p: 0 }}>
            <Typography sx={{
              width: '100%', height: '100%', p: 1,color: '#F1F1F1',
              '&:hover, &:focus': {
                backgroundColor: '#684FDD',
              },
            }}>
              {t('filterAll')}
            </Typography>
          </Option>
          {status &&
            status.map(({ title, id }) => (
              <Option key={id} value={title} sx={{
                p: 0, color: '#F1F1F1',
                '&[aria-selected="true"]': {
                  backgroundColor: '#684FDD',
                }
              }}> <Typography sx={{
                width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                '&:hover, &:focus': {
                  backgroundColor: '#684FDD',

                },
              }}>{title}</Typography>
               
              </Option>
            ))}
        </Select>
        <Select
          placeholder={t('card_search_bank')}
          variant="outlined"
          slotProps={{
            listbox: {
              sx: {
                '--List-padding': '0px',
                '--ListItem-radius': '0px',
              },
            },
          }}
          indicator={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#ADB5BD"
                d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
              />
            </svg>
          }
          onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
            handleChangeBank(_event, newValue);
          }}
          value={filterQueries.searchBank}
          sx={{
            color: '#CED4DA',
            width: '180px',
            '--Input-radius': '8px',
            '--Select-placeholderOpacity': 1,
            mt:isMobile ? 1 : 0,

          }}
        >
          <Option
            key={'all'}
            value={'all'}
            sx={{
              p: 0,
            }}
          >
            <Typography sx={{
              width: '100%', height: '100%', p: 1,
              color: '#F1F1F1',
              '&:hover, &:focus': {
                backgroundColor: '#684FDD',
              },
            }}>
              {t('filterAll')}
            </Typography>
          </Option>
          {banks &&
            banks.map(({ title, id }) => (
              <Option
                key={id}
                value={title}
                sx={{
                  p: 0,
                  '&[aria-selected="true"]': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1,
                  color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',

                  },
                }}>
                  {title}
                </Typography>
              </Option>
            ))}
        </Select>
        <Select
          placeholder={t('history_table_laber')}
          variant="outlined"
          slotProps={{
            listbox: {
              sx: {
                '--List-padding': '0px',
                '--ListItem-radius': '0px',
              },
            },
          }}
          indicator={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#ADB5BD"
                d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
              />
            </svg>
          }
          onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
            handleChangeSort(_event, newValue);
          }}
          value={filterQueries.sort}
          sx={{
            color: '#CED4DA',
            width: '180px',
            p: 1,
            '--Input-radius': '8px',
            '--Select-placeholderOpacity': 1,
            mt: isMobile ? 1 : 0,
          }}

        >
          <Option key={'all'} value={'all'} sx={{
            p: 0, color: '#F1F1F1',
            '&[aria-selected="true"]': {
              backgroundColor: '#684FDD',

            },
          }}>
            <Typography sx={{
              width: '100%', height: '100%', p: 1,
              color: '#F1F1F1',
              '&:hover, &:focus': {
                backgroundColor: '#684FDD',
              },
            }}> {t('filterAll')}</Typography>
          </Option>
          <Option value={'a-b'} variant="plain" sx={{
            p: 0, color: '#F1F1F1',
            '&[aria-selected="true"]': {
              backgroundColor: '#684FDD',

            },
          }}><Typography sx={{
            width: '100%', height: '100%', p: 1,
            color: '#F1F1F1',
            '&:hover, &:focus': {
              backgroundColor: '#684FDD',
            },
          }}>
            {t('history_table_minPrice')}</Typography>
        </Option>
          <Option value={'a+b'} variant="plain" sx={{
            p: 0, color: '#F1F1F1',
            '&[aria-selected="true"]': {
              backgroundColor: '#684FDD',

            },
          }}><Typography sx={{
            width: '100%', height: '100%', p: 1,
            color: '#F1F1F1',
            '&:hover, &:focus': {
              backgroundColor: '#684FDD',
            },
          }}>{t('history_table_maxPrice')}
            </Typography>
            </Option>
        </Select>
        <Box>
          <Button variant="outlined" onClick={toggleOpenModal} startDecorator={
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
              <path
                fill="url(#a)"
                d="M18 9.5v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-7h18Zm-5-9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H0v-3a2 2 0 0 1 2-2h2v-1a1 1 0 0 1 2 0v1h6v-1a1 1 0 0 1 1-1Z"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1=".2"
                  x2="18"
                  y1="10.8"
                  y2="10.8"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#684FDD" />
                  <stop offset="1" stopColor="#2F92AC" />
                </linearGradient>
              </defs>
            </svg>
          }
            sx={{
              mt: isMobile ? 1 : 0,
              padding: '12px',
            }}>
            {(filterQueries.sDate &&
              filterQueries.fDate &&
              `${formatDateText(filterQueries.sDate)} - ${formatDateText(filterQueries.fDate)}`) ||
              t('search_period')}
          </Button>
        </Box>
      </Box>
      {isMobile && (<ListMobileQac list={notifications} />)}
      {!isMobile && (<QACHistoryTrans transactions={notifications} />
      )}
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: 2,
          }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
          />
        </Box>
      )}
      <Modal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 4,
            boxShadow: 'lg',
          }}
        >
          <Typography level="title-lg" textAlign={'center'} sx={{ mb: 2 }}>
            {t('search_period')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(rangesByKey: RangeKeyDict) => handleChangeDate(rangesByKey as ItemRange)}
              moveRangeOnFirstSelection={false}
              ranges={datePickerState}
              maxDate={new Date()}
            />
          </Box>
          <Button
            sx={{
              display: 'block',
              m: '20px auto 0 auto',
              borderRadius: '8px',
              p: '6px 44px',
              background:
                'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
            onClick={handleAddDeadline}
          >
            {t('settings_save_btn')}
          </Button>
        </Sheet>
      </Modal>
    </Box>
  );
};

export default TransactionsQac;
