import { Table, Typography } from '@mui/joy';
import React from 'react';
import { Withdrawals } from '../../../utils/types';
import WithdrawalItem from './Item';
import { useTranslation } from 'react-i18next';

const WithdrawalItems = ({ items }: { items: Withdrawals[] }) => {
  const { t } = useTranslation();
  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{ color: "#F1F1F1" }}>{t('withdrawalDateStart')}</th>
            <th style={{ textAlign: 'center', color:"#F1F1F1" }}>{t('withdrawalStatus')}</th>
            <th style={{ textAlign: 'center',color:"#F1F1F1" }}>{t('withdrawalAmount')}</th>
            <th style={{ color: "#F1F1F1" }}>{t('withdrawalDateUpdated')}</th>
            <th style={{ textAlign: 'center',color:"#F1F1F1" }}>{t('withdrawalWallet')}</th>
          </tr>
        </thead>
        <tbody>
          {items && items?.map((item: Withdrawals) => <WithdrawalItem key={item.id} item={item} />)}
        </tbody>
      </Table>
      {!items && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
    </>
  );
};
export default WithdrawalItems;
