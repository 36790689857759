export const formatDateText = (dateString: Date | string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
  return formattedDate.replace(/\//g, '-');
};

export const formatDateBE = (inputDate: string | Date) => {
  const months: { [key: string]: string } = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  const dateString = inputDate.toString();
  const parts = dateString.split(' ');
  const day = parseInt(parts[2], 10).toString().padStart(2, '0');
  const month = months[parts[1]];
  const year = parts[3];

  return `${year}-${month}-${day}`;
};
