import { Box, Button, List, ListDivider, ListItem, ListItemContent, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../utils/dateFormater';
import { maskCreditCardNumber } from '../../../../utils/formatCreditCard';
import { getStatusTextColor } from '../../../../utils/getStatusColor';
import { Transactions } from '../../../../utils/types';
import HistoryItemModal from '../HistoryItemModal';

const TransactionByIdMob = ({ transactionById }: { transactionById: Transactions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <List
      size="sm"
      sx={{
        position: 'relative',
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          position: 'relative',
        }}
      >
        <ListItemContent>
          <Typography level="title-md">{transactionById.label && transactionById.label}</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography level="body-xs">{t('history_table_card')}</Typography>
            <Typography level="body-xs">
              {maskCreditCardNumber(transactionById.pocket_address)}
            </Typography>
            <Typography level="title-sm">{transactionById.merchant_bank_title}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_priceUah')}</Typography>
            <Typography level="body-md">
              {Number(transactionById.confirmed_amount_uah).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_priceUsdt')}</Typography>
            <Typography level="body-md">
              {Number(transactionById.merchantAmount).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_startDate')}</Typography>
            <Typography level="body-md">{formatDateTime(transactionById.createdAt)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_endDate')}</Typography>
            <Typography level="body-md">{formatDateTime(transactionById.updatedAt)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_status')}</Typography>
            <Typography level="body-md" sx={{ color: getStatusTextColor(transactionById?.status) }}>
              {transactionById?.status &&
                transactionById.status.charAt(0).toUpperCase() +
                  transactionById?.status.slice(1).toLowerCase()}
            </Typography>
          </Box>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '3px',
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <HistoryItemModal
        id={transactionById.id}
        open={open}
        onClose={toggleDrawer(false)}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </List>
  );
};

export default TransactionByIdMob;
