import { Box } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { Transactions } from '../../../utils/types';
import HistoryItemMob from './HistoryItemQACMob';

const ListMobileQac = ({
  list,
}: {
  list: Transactions[];
}) => {
  const [listTransactions, setListTransactions] = useState(list);

  useEffect(() => {
    setListTransactions(list);
  }, [list]);


  return (
    <Box sx={{ display: 'block' }}>
      {listTransactions?.map((notification: Transactions) => (
        <HistoryItemMob listItem={notification} key={notification.id} />
      ))}
    </Box>
  );
};

export default ListMobileQac;
