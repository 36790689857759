import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import api, { token } from '../../services/axiosConfig';
import { AxiosError } from 'axios';


export const signIn = createAsyncThunk(
  'auth/signin',
  async ({ email, password }: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await api.post('auth/merchant/login/', { email, password });
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_email'), {
        position: 'top-right',
      });

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const authenticate = createAsyncThunk(
  'auth/2fa',
  async ({ email, code }: { email: string; code: string }, thunkAPI) => {
    try {
      const response = await api.post('auth/merchant/2fa/authenticate/', {
        code,
        email: email,
      });

      token.set(response.data.access_token);
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_2fa'), {
        position: 'top-right',
      });
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUser = createAsyncThunk('auth/getUser', async (_: void, thunkAPI) => {
  try {
    const response = await api.get('account/merchant/');
    return response.data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log(error.message);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const logOut = () => {
  token.unset();
  localStorage.removeItem('persist:auth');
  window.location.reload();
};

export const registration = async (formData: object, verificationlink: string | undefined) => {
  try {
    const { data } = await api.put(`auth/registration/${verificationlink}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  } catch {
    toast.error('Something went wrong with registration', {
      position: 'top-right',
    });
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const { data } = await api.post(`auth/operator/forgot-password`, {
      email,
    });

    return data;
  } catch {
    toast.error(i18n.t('toast_forgotPassword'), {
      position: 'top-right',
    });
  }
};

export const resetPassword = async (email: string, resetToken: string, newpassword: string) => {
  try {
    const { data } = await api.post(`auth/operator/reset-password`, {
      email,
      resetToken,
      newpassword,
    });

    return data;
  } catch {
    toast.error(i18n.t('toast_resetPassword'), {
      position: 'top-right',
    });
  }
};
export const refreshToken = createAsyncThunk(
  'auth/merchant/refresh',
  async (refresh_token: string | undefined, thunkAPI) => {
    try {
      const { data } = await api.post(`auth/merchant/refresh`, { refresh_token });
      token.set(data?.access_token);
      return data;
    } catch (error) {
      toast.error('Something went wrong with refresh token', {
        position: 'top-right',
      });
      return thunkAPI.rejectWithValue(error);
    }
  }
);