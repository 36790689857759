import { Box, List, ListDivider, ListItem, ListItemContent, Typography } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../utils/dateFormater';
import { Withdrawals } from '../../../utils/types';
import { getStatusTextColor } from '../../../utils/getStatusColor';

const ItemMobile = ({ items }: { items: Withdrawals[] }) => {
  const { t } = useTranslation();

  return (
    <>
      {items &&
        items?.map((item: Withdrawals) => (
          <List
            size="sm"
            sx={{
              position: 'relative',
              '--ListItem-paddingX': 0,
            }}
          >
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                position: 'relative',
              }}
            >
              <ListItemContent>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Typography level="body-xs">{t('withdrawalDateStart')}</Typography>
                  <Typography level="body-xs" sx={{ color: '#F1F1F1' }}>
                    {formatDateTime(item.createdAt)}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Typography level="body-xs">{t('withdrawalStatus')}</Typography>
                  <Typography
                    level="body-md"
                    sx={{ color: getStatusTextColor(item.status), fontWeight: 700 }}
                  >
                    {item?.status &&
                      item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Typography level="body-xs">{t('withdrawalAmount')}</Typography>
                  <Typography level="body-md" sx={{ color: '#F1F1F1' }}>
                    {Number(item.usdtAmount).toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Typography level="body-xs">{t('withdrawalDateUpdated')}</Typography>
                  <Typography level="body-xs" sx={{ color: '#F1F1F1' }}>
                    {formatDateTime(item.updatedAt)}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Typography level="body-xs">{t('withdrawalWallet')}</Typography>
                  <Typography level="body-xs" sx={{ overflowWrap: 'anywhere', color: '#F1F1F1' }}>
                    {item.personal_wallet}
                  </Typography>
                </Box>
              </ListItemContent>
            </ListItem>
            <ListDivider />
          </List>
        ))}
    </>
  );
};

export default ItemMobile;
