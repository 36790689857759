import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Container from '@mui/joy/Container';
import { NavLink } from 'react-router-dom';
import { Input } from '@mui/joy';
import Button from '@mui/joy/Button';

import { useForm } from 'react-hook-form';
import css from './SignUp.module.css';

import { namePattern, passwordPattern, phonePattern } from '../../utils/patterns';
import Error from '../../components/Error';

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import useWidth from '../../hooks/useWidth';

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  interface IFormInput {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const { isTable } = useWidth();

  const onSubmit = async () => {
    //todo
  };

  const password = watch('password');
  const validateConfirmPassword = (value: string) => {
    return value === password || 'Passwords do not match';
  };

  return (
    <>
      <Box
        sx={theme => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(255 255 255 / 0.6)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <div style={{ display: 'flex', height: (!isTable && '100vh') || 'auto' }}>
          <div
            style={{
              width: '100%',
              padding: '30px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  component="h2"
                  style={{
                    fontWeight: '700',
                    marginBottom: '20px',
                    fontSize: '32px',
                  }}
                >
                  Registration
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
                  <Input
                    {...register('firstName', {
                      required: 'This input is required.',
                      pattern: {
                        value: namePattern,
                        message: 'Invalid first name',
                      },
                      minLength: { value: 3, message: 'enter values longer than 2 characters' },
                      maxLength: {
                        value: 100,
                        message: 'enter values shorter than 100 characters',
                      },
                    })}
                    id="firstName"
                    placeholder="First Name"
                    name="firstName"
                    className={css.fullInput}
                    autoComplete="firstName"
                    autoFocus
                  />
                  <Error errors={errors} name="firstName" />
                  <Input
                    {...register('lastName', {
                      required: 'This input is required.',
                      pattern: {
                        value: namePattern,
                        message: 'Invalid last name',
                      },
                      minLength: { value: 3, message: 'enter values longer than 2 characters' },
                      maxLength: {
                        value: 100,
                        message: 'enter values shorter than 100 characters',
                      },
                    })}
                    required
                    id="lastName"
                    placeholder="Last Name"
                    name="lastName"
                    className={css.fullInput}
                    autoComplete="lastName"
                  />
                  <Error errors={errors} name="lastName" />

                  <Input
                    {...register('phoneNumber', {
                      pattern: {
                        value: phonePattern,
                        message: 'You shoud use only numbers',
                      },
                      required: 'This input is required.',
                      minLength: {
                        value: 10,
                        message: 'The minimum phone numbers length is 10 characters',
                      },
                      maxLength: {
                        value: 12,
                        message: 'The maximum phone numbers length is 12 characters',
                      },
                    })}
                    required
                    id="phoneNumber"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    className={css.fullInput}
                    autoComplete="phoneNumber"
                  />
                  <Error errors={errors} name="phoneNumber" />
                  <div className={css.passWrap}>
                    <Input
                      {...register('password', {
                        pattern: {
                          value: passwordPattern,
                          message: 'Invalid password, you must use numbers and letters',
                        },
                        required: 'This input is required.',
                        minLength: {
                          value: 8,
                          message: 'The minimum password length is 8 characters',
                        },
                        maxLength: {
                          value: 32,
                          message: 'The maximum password length is 32 characters',
                        },
                      })}
                      required
                      name="password"
                      placeholder="Password"
                      className={css.fullInput}
                      type={(showPassword && 'text') || 'password'}
                      id="password"
                      autoComplete="password"
                    />
                    <button
                      className={css.buttonPass}
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {(showPassword && (
                        <AiOutlineEyeInvisible style={{ width: '24px', height: '24px' }} />
                      )) || <AiOutlineEye style={{ width: '24px', height: '24px' }} />}
                    </button>
                  </div>
                  <Error errors={errors} name="password" />
                  <div className={css.passWrap}>
                    <Input
                      {...register('confirmPassword', {
                        required: 'This input is required.',
                        validate: validateConfirmPassword,
                      })}
                      required
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className={css.fullInput}
                      type={(showConfirmPassword && 'text') || 'password'}
                      id="confirmPassword"
                      autoComplete="confirmPassword"
                    />
                    <button
                      className={css.buttonPass}
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {(showConfirmPassword && (
                        <AiOutlineEyeInvisible style={{ width: '24px', height: '24px' }} />
                      )) || <AiOutlineEye style={{ width: '24px', height: '24px' }} />}
                    </button>
                  </div>
                  <Error errors={errors} name="confirmPassword" />
                  <Button className={css.button} type="submit">
                    Sign Up
                  </Button>
                  <NavLink to="/login" style={{ color: 'var(--text-second)', textAlign: 'center' }}>
                    Do you have an account?
                    <span style={{ color: 'var(--accent-main)' }}> Sign In</span>
                  </NavLink>
                </form>
              </Box>
            </Container>
          </div>
        </div>
      </Box>
      <Box
        sx={theme => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
    </>
  );
};

export default SignUp;
