import { Option, Select, selectClasses } from '@mui/joy';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdLanguage } from 'react-icons/md';

const languages = [
  { name: 'EN', value: 'en-US' },
  { name: 'UA', value: 'uk-UA' },
];

const ToggleLanguage = () => {
  const { i18n } = useTranslation();
  const defaultLang = localStorage.getItem('i18nextLng');

  const initialLang = languages.some((lang: { value: string }) => lang.value === defaultLang)
    ? defaultLang
    : 'en-US';

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleChange = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    changeLanguage(newValue as string);
  };

  return (
    <Box>
      <Select
        onChange={handleChange}
        startDecorator={<MdLanguage />}
        defaultValue={initialLang}
        color="neutral"
        variant="plain"
        slotProps={{
          listbox: {
            sx: {
              '--List-padding': '0px',
              '--ListItem-radius': '0px',
            },
          },
        }}
        indicator={
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
            <path
              fill="#ADB5BD"
              d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
            />
          </svg>
        }
        sx={{
          [`& .${selectClasses.indicator}`]: {
            transition: '0.2s',
            [`&.${selectClasses.expanded}`]: {
              transform: 'rotate(-180deg)',
            },
          }, 
         
        }}


      >
        {languages &&
          languages.map((language: { value: string; name: string }) => (
            <Option key={language.value} value={language.value} >
              {language.name}
            </Option>
          ))}
      </Select>
    </Box>
  );
};

export default ToggleLanguage;
