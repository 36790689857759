import { createSlice } from '@reduxjs/toolkit';

import { StatusForAll } from '../../utils/status';
import { Transactions } from '../../utils/types';

import { signIn, authenticate, getUser, refreshToken } from './operations';
import { clearEmail, updateBalance } from './actions';
interface AuthState {
  user: {
    id: number | null;
    balance: number;
    name: string;
    email: string;
    qrCode: string;
    tax: number;
    calculatedTax: string;
    apiKey: string;
    acquiring_tax: number;
  };
  activeTransactions: Transactions[];
  email: string;
  access_token: string | null;
  status: string;
  isLoggedIn: boolean;
  refresh_token: string | null;
}

const initialState: AuthState = {
  user: {
    id: null,
    balance: 0,
    name: '',
    qrCode: '',
    tax: 0,
    email: '',
    calculatedTax: '',
    apiKey: '',
    acquiring_tax: 0,
  },
  activeTransactions: [],
  email: '',
  access_token: '',
  status: StatusForAll.init,
  isLoggedIn: false,
  refresh_token: '',
};

const authSlice = createSlice({
  name: 'auth',
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = StatusForAll.success;
        state.access_token = initialState.access_token;
        state.email = action.payload.email;
        state.user = {
          name: action.payload.name,
          id: action.payload.id,
          balance: action.payload.balance,
          qrCode: action.payload.qrCode,
          tax: action.payload.tax,
          calculatedTax: action.payload.calculated_tax,
          email: action.payload.email,
          apiKey: action.payload.api_key,
          acquiring_tax: action.payload.acquiring_tax
        };
        (state.activeTransactions = initialState.activeTransactions), (state.isLoggedIn = false);
      })
      .addCase(signIn.rejected, state => {
        state.status = StatusForAll.error;
        state.user = initialState.user;
        state.isLoggedIn = true;
      })
      .addCase(authenticate.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.status = StatusForAll.success;
        state.isLoggedIn = !!action.payload.access_token;
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;

        state.user = {
          name: action.payload.name,
          id: action.payload.id,
          balance: action.payload.balance,
          qrCode: action.payload.qrCode,
          tax: action.payload.tax,
          calculatedTax: action.payload.calculated_tax,
          email: action.payload.email,
          apiKey: action.payload.api_key,
          acquiring_tax: action.payload.acquiring_tax
        };
        state.email = action.payload.email;
      })
      .addCase(authenticate.rejected, state => {
        state.status = StatusForAll.error;
        state.isLoggedIn = false;
      })

      .addCase(getUser.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = {
          name: action.payload.name,
          id: action.payload.id,
          balance: action.payload.balance,
          qrCode: action.payload.qrCode,
          tax: action.payload.tax,
          calculatedTax: action.payload.calculated_tax,
          email: action.payload.email,
          apiKey: action.payload.api_key,
          acquiring_tax: action.payload.acquiring_tax
        };
      })
      .addCase(getUser.rejected, state => {
        state.status = StatusForAll.error;
      }).addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.access_token = action.payload.access_token;
      })
      .addCase(refreshToken.rejected, state => {
        state.isLoggedIn = false;
      }).addCase(updateBalance, (state, action) => {
        state.user.balance = action.payload;
      }).addCase(clearEmail, (state, action) => {
        state.email = action.payload;
      });
  },
});
export default authSlice.reducer;
