import { Box, Typography } from '@mui/joy';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { useWithdrawals } from '../../hooks/useWithdrawals';
import WithdrawalItems from '../../components/Withdrawal/Item/Items';
import ItemMobile from '../../components/Withdrawal/Item/ItemMobile';
import useWidth from '../../hooks/useWidth';

const Withdrawals = () => {
  const { t } = useTranslation();
  const { items, pageCount, handlePageClick } = useWithdrawals();
  const { isMobile } = useWidth();
  return (
    <Box sx={{ mb: 3, pl: 3 }}>
      <Typography level="h2" sx={{ p: '20px 0 ' }}>
        {t('withdraw_history')}
      </Typography>
     {!isMobile &&(<WithdrawalItems items={items} />)} 
      {isMobile && (<ItemMobile items={items} />)}
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: 2,
          }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
          />
        </Box>
      )}
    </Box>
  );
};

export default Withdrawals;
