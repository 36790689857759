import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, Divider, Stack, Typography, Box } from '@mui/joy';
import classes from './Settings.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LuCopyCheck } from 'react-icons/lu';
import { LuCopy } from 'react-icons/lu';
import { generateApiKey } from '../../services/merchant';
import { toast } from 'react-toastify';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { AppDispatch } from '../../redux/store';
import { getUser } from '../../redux/auth/operations';

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useSelector(getGlobalUser);
  const [isCopied, setIsCopied] = useState(false);
  const [apiDisabled, setApiDisabled] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleGenerateApi = async () => {
    try {
      const response = await generateApiKey();
      if (response) {
        await dispatch(getUser());
        setApiDisabled(true);
        toast.success(t('toast_generateApi_success'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleToggleVisibility = () => {
    setShowAPI((showAPI) => !showAPI);
    setIsCopied(false);
  };
  const obscuredAPI = showAPI ? user?.apiKey : '*'.repeat(user?.apiKey?.length);

  return (
    <Box sx={{ mb: 3, p: 3, width: '100%' }}>
      <Stack spacing={3}>
        <Typography level="h2">{t('settings_header')}</Typography>
        <Card>
          <Typography level="h4">{t('settings_checkbox_header')}</Typography>

          <Divider />
          <CardContent>
            <div className={classes.box}>
              {obscuredAPI}
              <Box sx={{ display: 'flex' }}>
                <button className={classes.buttonApi} type="button" onClick={handleToggleVisibility}>
                  {showAPI ? (
                    <AiOutlineEyeInvisible style={{ width: '24px', height: '24px' }} />
                  ) : (
                    <AiOutlineEye style={{ width: '24px', height: '24px' }} />
                  )}
                </button>
                <CopyToClipboard text={user?.apiKey} onCopy={onCopyText}>
                  <span className={classes.iconCopy}>
                    {(isCopied && <LuCopyCheck size={17} />) || <LuCopy size={17} />}
                  </span>
                </CopyToClipboard>
              </Box>
            </div>
          </CardContent>

          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="outlined"
              onClick={handleGenerateApi}
              disabled={apiDisabled}>{t('settings_regenerate_api')}</Button>
          </CardActions>
        </Card>
      </Stack>
    </Box>
  );
};

export default Settings;