import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import css from './SignIn.module.css';

import RightImg from '../../components/RightImg';

import { emailPattern } from '../../utils/patterns';
import useWidth from '../../hooks/useWidth';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';

const SignInKey = () => {
  interface IFormInput {
    key: string;
  }

  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getGlobalUser);
  const { isMobile } = useWidth();

  const { register, handleSubmit } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/`);
    }
  }, [isLoggedIn, navigate]);

  const onSubmit = () => {
    //todo dispatch(signIn(data));
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className={css.wrapper}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ fontWeight: '700', marginBottom: '20px', fontSize: '32px' }}
            >
              Login Merchant
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
              <input
                {...register('key', {
                  required: 'This input is required.',
                  pattern: {
                    value: emailPattern,
                    message: 'Invalid email address',
                  },
                })}
                required
                id="key"
                name="key"
                placeholder="Key"
                className={css.fullInput}
                autoComplete="key"
                autoFocus
              />

              <button className={css.button} type="submit">
                Sign In
              </button>
            </form>
          </Box>
        </Container>
      </div>
      {!isMobile && <RightImg />}
    </div>
  );
};

export default SignInKey;
