import { Box, Divider, Drawer, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { LuCopy, LuCopyCheck } from 'react-icons/lu';
import { getQacTransactionById } from '../../services/operators';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';
import { Transactions } from '../../utils/types';
import classes from '../../pages/Settings/Settings.module.css';
const HistoryQACModal = ({
  id,
  open,
  onClose,
  onKeyDown,
  onClick,
}: {
  id: string;
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onKeyDown: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const [transaction, setTransaction] = useState<Transactions | null>(null);
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);


  useEffect(() => {
    const fetch = async () => {
      const data = await getQacTransactionById(Number(id));
      if (data) {
        setTransaction(data);
      }
    };
    if (open) {
      fetch();
    }
  }, [open]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  };
  const status = {
    pending: 'PENDING',
    processing: 'PROCESSING',
    expired: "EXPIRED"
  }

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <Box role="presentacion" onClick={onClick} onKeyDown={onKeyDown}>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }} level="h4">
            {t('details_trans_header')}
          </Typography>
          {!transaction && (
            <Typography sx={{ mb: 3 }} level="body-md">
              {t('not_found')}
            </Typography>
          )}
          {transaction && (
            <>
              <Box>
                <Typography level="title-lg" sx={{ color: "#947EFE", mb: 2 }}>
                  {t('details_trans_name')}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ mr: '6px', overflowWrap:'anywhere' }} level="body-md">{`ID: `} {truncateString(`${transaction.id}`, 27)}
                  </Typography>
                  <CopyToClipboard text={transaction.id} onCopy={onCopyText}>
                    <span className={classes.iconCopy}>
                      {(isCopied && <LuCopyCheck size={17} />) || <LuCopy size={17} />}
                    </span>
                  </CopyToClipboard>
                </Box>
                <Typography level="body-md">
                  {`Transaction id: ${transaction?.transaction_num_id}`}
                </Typography>
                <Typography level="body-md">
                  {`Order id: ${transaction.order_id}`}
                </Typography>
                <Typography level="body-md">
                  {`Customer id: ${transaction.customer_id}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_startDate')}: ${formatDateTime(transaction.createdAt)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_endDate')}: ${formatDateTime(transaction.updatedAt)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_course')}: ${parseFloat(transaction.currentCourse).toFixed(
                    2
                  )}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUah')}: ${parseFloat(transaction.amount).toFixed(2)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUsdt')}: ${parseFloat(transaction.merchantAmount).toFixed(
                    2
                  )}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('feeTax', {
                    tax: parseFloat(transaction.merchant_fee).toFixed(
                      2
                    )
                  })}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('fee', {
                    tax: parseFloat(transaction.merchant_profit).toFixed(
                      2
                    )
                  })}`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Typography level="body-md">{t('transaction_bank') + ':'}</Typography>
                  <Typography
                    level="body-md"
                  >
                    {transaction.
                      merchantBank.bank.title && transaction.
                        merchantBank.bank.title || t('not_found')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Typography level="body-md">{t('history_table_status') + ':'}</Typography>
                  <Typography
                    level="body-md"
                    sx={{ color: getStatusTextColor(transaction.status) }}
                  >
                    {transaction?.status &&
                      transaction.status.charAt(0).toUpperCase() +
                      transaction.status.slice(1).toLowerCase()}
                  </Typography>
                </Box>
                {!(transaction.status === status.pending || transaction.status === status.processing || transaction.status === status.expired) && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    {transaction.status_changed_by && (
                      <>  <Typography level="body-md">{t('history_table_status_changedBy') + ':'}</Typography>
                    <Typography
                      level="body-md"
                      sx={{ color: "#f1f1f1" }}
                    >
                      {transaction.status_changed_by}
                        </Typography>
                      </>  
                    )}
                 </Box>
                )}
                <Divider />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default HistoryQACModal;
