import { Box, Typography  } from '@mui/joy';
import React from 'react';
import { Link } from 'react-router-dom';
const Logo = () => {
  return (
    <Link to="/">
      <Box sx={{ display: 'flex' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none">
          <path
            fill="url(#a)"
            d="M20.3.3a13.3 13.3 0 0 1 5.5 25.5A13.3 13.3 0 1 1 8.2 8.2 13.3 13.3 0 0 1 20.3.3Zm-5 11.7H12v1.7a4.2 4.2 0 0 0-.3 8.3h3.8a.8.8 0 0 1 0 1.7H8.7V27H12v1.7h3.3V27a4.2 4.2 0 0 0 .3-8.3H12a.8.8 0 0 1 0-1.7h6.8v-3.3h-3.4V12Zm5-8.3A10 10 0 0 0 13 7a13.3 13.3 0 0 1 14 14.1 10 10 0 0 0-6.6-17.4Z"
          />
          <defs>
            <linearGradient
              id="a"
              x1=".8"
              x2="33.7"
              y1="19.4"
              y2="19.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#684FDD" />
              <stop offset="1" stopColor="#2F92AC" />
            </linearGradient>
          </defs>
        </svg>
        <Typography level="h4" sx={{ color: '#FFFFFF', fontWeight: 600, fontSize: '24px', ml: 1 , display:'flex'}}>
          Inside
          <span
            style={{
              background: 'linear-gradient(to left, rgba(104, 79, 221, 1), rgba(47, 146, 172, 1))',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              display: 'inline-block',
            }}
          >
            Ex
          </span>
        </Typography>
      </Box>
    </Link>
  );
};

export default Logo;
