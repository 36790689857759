import { Box, Button, List, ListDivider, ListItem, ListItemContent, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../utils/dateFormater';
import { getStatusTextColor } from '../../../utils/getStatusColor';
import { Transactions } from '../../../utils/types';
import HistoryQACModal from '../HistoryQACModal';

const HistoryItemQACMob = ({ listItem }: { listItem: Transactions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <List
      size="sm"
      sx={{
        position: 'relative',
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          position: 'relative',
        }}
      >
        <ListItemContent sx={{ pt: 3 }}>
          <Typography level="body-xs">{listItem.label}</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography level="body-xs">{listItem.jar_link}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_priceUah')}</Typography>
            <Typography level="body-md">{Number(listItem.amount).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_priceUsdt')}</Typography>
            <Typography level="body-md">{Number(listItem.merchantAmount).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('transaction_bank')}</Typography>
            <Typography level="body-md">{listItem.merchant_bank_title && listItem.merchant_bank_title || t('not_found')}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_startDate')}</Typography>
            <Typography level="body-md">{formatDateTime(listItem.createdAt)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_endDate')}</Typography>
            <Typography level="body-md">{formatDateTime(listItem.updatedAt)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_status')}</Typography>
            <Typography level="body-md" sx={{ color: getStatusTextColor(listItem?.status) }}>
              {listItem?.status &&
                listItem.status.charAt(0).toUpperCase() + listItem?.status.slice(1).toLowerCase()}
            </Typography>
          </Box>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '3px',
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <HistoryQACModal
        id={listItem.id}
        open={open}
        onClose={toggleDrawer(false)}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </List>
  );
};

export default HistoryItemQACMob;
