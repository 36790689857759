import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { refreshToken } from '../redux/auth/operations';
import { store } from '../redux/store';
const API = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API,
});

export const token = {
  set(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    api.defaults.headers.common.Authorization = '';
  },
};

api.interceptors.request.use(
  async config => {
    const persistData: string | null = localStorage.getItem('persist:auth');
    if (persistData) {
      const parsedData = JSON.parse(persistData);
      const token = JSON.parse(parsedData.access_token);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

interface QueueItem {
  resolve: () => void;
  reject: () => void;
  originalRequest: AxiosRequestConfig;
}

let isRefreshing = false;
const failedQueue: QueueItem[] = [];

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const persistData: string | null = localStorage.getItem('persist:auth');
          if (persistData) {
            const parsedData = JSON.parse(persistData);
            const refresh_token = JSON.parse(parsedData.refresh_token);

            const refreshTokenResult = await store.dispatch(refreshToken(refresh_token));

            if (refreshTokenResult.payload && refreshTokenResult.payload.user) {
              token.set(refreshTokenResult.payload.user?.access_token || '');
              originalRequest.headers.Authorization = `Bearer ${refreshTokenResult.payload.user?.access_token || ''}`;
              return api(originalRequest);
            }
          }
        } catch (refreshError) {
          localStorage.clear();
          window.location.replace('/login');
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise<void>((resolve, reject) => {
          failedQueue.push({ resolve, reject, originalRequest });
        });
      }
    }

    if (error.response?.status === 'CORS error') {
      toast.error('CORS error');
    }

    return Promise.reject(error);
  }
);
export default api;
