import { getConstants } from "../services/operators";
// export const PAGE_SIZE = 10;

export const getPageSizes = async () => {
    const constants = await getConstants();
    if (constants && constants.DEFAULT_PAGINATION) {
        return constants.DEFAULT_PAGINATION;
    } else {
        return 10; 
    }
}
