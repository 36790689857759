import { useEffect, useState } from 'react';
import { getAllTransactions } from '../services/operators';
import { getPageSizes } from '../utils/pagination';
import { Transactions } from '../utils/types';
export const useTransaction = ({
  filterQueries,
}: {
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  };
}) => {
  const [notifications, setNotifications] = useState<Transactions[] | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | null>(null);
 
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getPageSizes();
        setPageSize(data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const pageCount = Math.ceil(count / (pageSize || 10)); 
 
  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    if (pageSize === null) {
      return;
    }
    const fetch = async () => {
      const transactions = await getAllTransactions(currentPage, pageSize, filterQueries);
      const filteredNotifications = transactions?.transactions;
      setNotifications(filteredNotifications);
      setCount(transactions?.count);
    };
    fetch();
  }, [currentPage, filterQueries,pageSize]);

  return { notifications, count, handlePageClick, pageCount };
};
