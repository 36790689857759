import { Box, Button, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Transactions from '../../components/Dashboard/Widgets/Transactions';
import Turnovers from '../../components/Dashboard/Widgets/Turnovers';

import useWidth from '../../hooks/useWidth';
import { formatDateBE, formatDateText } from '../../utils/datePickerFormat';
import DatePickerModal from '../../components/DatePicker';

import { getAllStatistics } from '../../services/operators';
import { Stats, itemType } from '../../utils/types';

import { getLastWeeksDate } from '../../utils/dateFormater';
const defaultDate = new Date();

type filterData = {
  sDate: string;
  fDate: string;
};
const initialState = {
  sDate: formatDateBE(getLastWeeksDate().toString()),
  fDate: formatDateBE(defaultDate.toString()),
};
type ItemRange = {
  selection: itemType;
}
const Dashboard = () => {
  const [filterQueries, setFilterQueries] = useState<filterData>(initialState);
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState<null | Stats>(null);
  const { isMobile, isLargeDesktop } = useWidth();
  const { t } = useTranslation();
  const toggleOpenModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetch = async () => {
      const statsData = await getAllStatistics(filterQueries);

      setStats(statsData);
    };
    fetch();
  }, [filterQueries]);
  const handleChange = (item: ItemRange)=>{
    setDatePickerState([item.selection]);
  };

  const handleAddDeadline = async () => {
    setFilterQueries(prev => {
      return {
        ...prev,
        sDate: formatDateBE(datePickerState[0].startDate),
        fDate: formatDateBE(datePickerState[0].endDate),
      };
    });

    setOpen(!open);
  };
  return (
    // <Box>
    <Box sx={{ mb: 3, pl: (isMobile && '0') || 3, width: '100%' }}>
      <Typography
        level="h2"
        sx={{
          p: '20px 0 16px 0 ',
          fontWeight: 600,
          fontSize: isMobile ? '20px' : '32px',
          color: '#F1F1F1',
        }}
      >
        {t('dashboard_header')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
        <Typography
          level="title-md"
          sx={{ fontWeight: 400, fontSize: isMobile ? '14px' : '16px', color: '#CED4DA' }}
        >
          {' '}
          {t('periods_search')}
        </Typography>
        <Box>
          <Button
            variant="outlined"
            color="neutral"
            onClick={toggleOpenModal}
            sx={{ p: isMobile ? '12px 8px' : '12px 19px' }}
          >
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
              <path
                fill="url(#a)"
                d="M18 9.5v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-7h18Zm-5-9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H0v-3a2 2 0 0 1 2-2h2v-1a1 1 0 0 1 2 0v1h6v-1a1 1 0 0 1 1-1Z"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1=".2"
                  x2="18"
                  y1="10.8"
                  y2="10.8"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#684FDD" />
                  <stop offset="1" stopColor="#2F92AC" />
                </linearGradient>
              </defs>
            </svg>
            <Typography sx={{ ml: 1, color: '#F1F1F1', fontSize: isMobile ? '10px' : '14px' }}>
              {(filterQueries.sDate &&
                filterQueries.fDate &&
                `${formatDateText(filterQueries.sDate)} - ${formatDateText(
                  filterQueries.fDate
                )}`) ||
                t('search_period')}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: !isLargeDesktop ? 'block' : 'flex' }}>
        <Turnovers stats={stats} filterQueries={filterQueries} />
        <Transactions stats={stats} filterQueries={filterQueries} />
      </Box>

      {/* <ChartActivity />  */}
      <DatePickerModal
        handleChange={handleChange}
        open={open}
        setOpen={setOpen}
        datePickerState={datePickerState}
        handleAddDeadline={handleAddDeadline}
      />
    </Box>
  );
};

export default Dashboard;
