import { Box, Button, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';
import { Transactions } from '../../utils/types';
import HistoryQACModal from './HistoryQACModal';

const HistoryItemQAC = ({
  notification,
}:
{
  notification: Transactions;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };
  return (
    <>
      <tr key={notification.id}>
        <td>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', color: '#F1F1F1' }}>
            <Typography level="body-md" sx={{ fontWeight: 600 }}>
              {parseFloat(notification.amount).toFixed(2)}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-md" sx={{color:'#F1F1F1'}}>{Number(notification.merchantAmount).toFixed(2)}</Typography>
        </td>
        <td>
          {notification.merchant_bank_title ? (
            <Typography level="body-md" sx={{ color: '#F1F1F1' }}>{notification.merchant_bank_title}</Typography>
          ) : (<Typography level="body-md">
            {t('not_found')}
          </Typography>)}
        </td>
        <td>
          <Typography level="body-md" sx={{color:'#F1F1F1'}}>{formatDateTime(notification.createdAt)}</Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{color:'#F1F1F1'}}>{formatDateTime(notification.updatedAt)}</Typography>
        </td>
        <td>
          <Typography
            level="body-md"
            sx={{
              color: getStatusTextColor(notification?.status),
              fontWeight: 500,
              overflowWrap: 'anywhere',
            }}
          >
            {notification?.status &&
              notification.status.charAt(0).toUpperCase() +
              notification.status.slice(1).toLowerCase()}
          </Typography>
        </td>
        <td>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              m: 0,
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </td>
      </tr>
      <HistoryQACModal
        id={notification.id}
        open={open}
        onClose={toggleDrawer(false)}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </>
  );
};
export default HistoryItemQAC;
