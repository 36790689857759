import { Box, Container, Typography, Input, Button } from '@mui/joy';
import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import css from './ForgotPassword.module.css';
import { emailPattern } from '../../utils/patterns';

import Error from '../../components/Error';
import { forgotPassword } from '../../redux/auth/operations';
import ToggleLanguage from '../../components/ToggleLanguage/ToggleLanguage';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [email, setEmail] = useState('');
  interface IFormInput {
    email: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const onSubmit = async (data: IFormInput) => {
    await forgotPassword(data.email);

    setIsSendEmail(true);
  };

  return (
    <>
      <Box
        sx={theme => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(255 255 255 / 0.6)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box sx={{ position: 'absolute', top: '20px', left: '20px' }}>
          <ToggleLanguage />
        </Box>

        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isSendEmail ? (
            <div style={{ paddingBottom: '80px' }}>
              <Container component="main" maxWidth="xs">
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography level="h2">{t('forgot_confirm_email')}</Typography>
                </Box>
              </Container>
            </div>
          ) : (
            <div style={{ paddingBottom: '80px' }}>
              <Container component="main" maxWidth="xs">
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    level="h2"
                    sx={{ fontWeight: '700', marginBottom: '20px', fontSize: '32px' }}
                  >
                    {t('forgot_header')}
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit(onSubmit)} className={css.form}>
                    <Input
                      {...register('email', {
                        required: t('validation_required'),
                        pattern: {
                          value: emailPattern,
                          message: t('validation_email'),
                        },
                      })}
                      id="email"
                      name="email"
                      placeholder={t('forgot_email')}
                      className={css.fullInput}
                      value={email}
                      onChange={handleChange}
                      autoComplete="email"
                    />
                    <Error errors={errors} name="email" />
                    <Button className={css.button} type="submit">
                      {t('forgot_btn')}
                    </Button>
                  </Box>
                </Box>
              </Container>
            </div>
          )}
        </div>
      </Box>
      <Box
        sx={theme => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
          },
        })}
      />
    </>
  );
};

export default ForgotPassword;
