import { toast } from 'react-toastify';
import api from './axiosConfig';
import i18n from 'i18next';
import { AxiosError } from 'axios';

const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;

export const getCardForPay = async (amount: number, key: string, bank: string) => {
  try {
    const { data } = await api.post(`transaction/merchant/createTransaction`, {
      bank_name: bank,
      amount,
      api_key: key,
      callback_url: CALLBACK_URL,
      customer_id: 1,
    });

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log('error.message', error);

    toast.error(i18n.t('toast_getCardForPay'));
  }
};

export const createNotification = async (sig: string | null) => {
  try {
    const { data } = await api.post('transaction/merchant/confirmTransaction', {
      sig,
      api_key: '$2a$10$yEOSMRgQY9TJ.Xd/ZJtcEeiHWXqtDntLTxMJud6Qv4S4R.sayb2ou',
    });

    return data;
  } catch (error) {
    toast.error(i18n.t('toast_createNotification'));
  }
};

export const generateApiKey = async () => {
  try {
    const { data } = await api.get(`account/merchant/generate/apiKey`);
    return data;
  } catch {
    toast.error(i18n.t('toast_generateApi_error'), {
      position: 'top-right',
    });
  }
};

export const getBanks = async () => {
  try {
    const { data } = await api.get(`banks/merchant`);
    return data;
  } catch {
    toast.error('error', {
      position: 'top-right',
    });
  }
};
