import { useEffect, useState } from 'react';
import { getWithdrawalsHistory } from '../services/operators';
import { socket } from '../socket';
import { getPageSizes } from '../utils/pagination';
import { Withdrawals } from '../utils/types';

export const useWithdrawals = () => {
  const [items, setItems] = useState<Withdrawals[] | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | null>(null); 

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getPageSizes(); 
        setPageSize(data); 
      } catch (error) {
        console.error( error);
      }
    };
    getData(); 
  }, []);

  const pageCount = Math.ceil(count / (pageSize || 10)); 

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };
  useEffect(() => {
    if (pageSize === null) {
      return;
    }
    const fetch = async () => {
      const response = await getWithdrawalsHistory(currentPage, pageSize);
      setItems(response?.[0]);
      setCount(response?.[1]);
    };
    fetch();
  }, [currentPage,pageSize]);

  useEffect(() => {
    socket.on('merchantWithdrawList', data => {
      setItems(data[0]);
      setCount(data[1]);
    });
    return () => {
      socket.off('merchantWithdrawList');
    };
  })
  return { items, count, handlePageClick, pageCount };
};
